<template>
  <div class="chart-tools">
    <div v-if="visible.timeRange">
      <el-popover placement="bottom" width="600" trigger="click" v-model="popoverVisible">
        <div class="time-range">
          <div>
            <div class="time-range-title">时间范围</div>

            <ul class="time-options-list">
              <li
                v-for="item in timeOptions"
                :key="item.value"
                @click="timeRangeChange(item)"
                :class="{ active: getMilliSecond(item.value) === currentTimeRange }"
              >
                {{ item.text }}
              </li>
            </ul>
          </div>

          <div>
            <div class="time-range-title">自定义时间</div>
            <div class="time-range-custom">
              <div class="title">开始时间</div>
              <el-date-picker
                v-model="startTime"
                type="datetime"
                size="small"
                value-format="timestamp"
                style="width: 100%;"
              >
              </el-date-picker>
            </div>

            <div class="time-range-custom">
              <div class="title">结束时间</div>
              <el-date-picker
                v-model="endTime"
                type="datetime"
                size="small"
                value-format="timestamp"
                style="width: 100%;"
              >
              </el-date-picker>
            </div>

            <div>
              <el-button type="primary" size="small" @click="selectCustomTime">确定</el-button>
              <el-button size="small" @click="popoverVisible = false">取消</el-button>
            </div>
          </div>
        </div>

        <el-button slot="reference" size="small">
          <i class="el-icon-time"></i>
          <span class="time-range-text">{{ formatTimeRangeText }}</span>
        </el-button>
      </el-popover>
    </div>

    <div v-if="visible.interval">
      <el-button
        size="small"
        @click="intervalSwitch"
        :type="currentInterval ? 'primary' : ''"
        :icon="currentInterval ? 'el-icon-loading' : 'el-icon-video-play'"
      >
        <span>{{ currentInterval ? $t("handle.closeRefresh") : $t("handle.openRefresh") }}</span>
      </el-button>
    </div>
  </div>
</template>

<script>
import { merge } from "lodash";
import { getMilliSecond } from "utils";
import moment from "moment";

export default {
  props: {
    timeRange: {
      type: Array
    },

    interval: {
      type: Boolean,
      default: false
    },

    visibleObj: {
      type: Object
    }
  },

  data() {
    return {
      timeOptions: [
        {
          text: this.$t("unit.time.fifteen"),
          value: "15m"
        },

        {
          text: this.$t("unit.time.thirty"),
          value: "30m"
        },

        {
          text: this.$t("unit.time.oneHour"),
          value: "1h"
        },

        {
          text: this.$t("unit.time.twoHour"),
          value: "2h"
        },

        {
          text: this.$t("unit.time.fiveHour"),
          value: "5h"
        },

        {
          text: this.$t("unit.time.twelveHour"),
          value: "12h"
        },

        {
          text: this.$t("unit.time.oneDay"),
          value: "1d"
        },
        {
          text: this.$t("unit.time.threeDay"),
          value: "3d"
        },

        {
          text: this.$t("unit.time.sevenDay"),
          value: "7d"
        }
      ],

      popoverVisible: false,
      startTime: "",
      endTime: ""
    };
  },

  computed: {
    visible() {
      return merge(
        {
          timeRange: true,
          interval: true
        },
        this.visibleObj
      );
    },

    currentInterval: {
      get() {
        return this.interval;
      },

      set(val) {
        this.$emit("update:interval", val);
      }
    },

    currentTimeRange() {
      this.startTime = this.timeRange[0];
      this.endTime = this.timeRange[1];
      return this.timeRange[1] - this.timeRange[0];
    },

    formatTimeRangeText() {
      let startTime = moment(this.timeRange[0]).format("YYYY-MM-DD HH:mm:ss ");
      let endTime = moment(this.timeRange[1]).format("YYYY-MM-DD HH:mm:ss");
      return `${startTime}-${endTime}`;
    },

    vendor() {
      return this.$route.query.vendor;
    },

    region() {
      return this.$route.query.region;
    },

    cluster() {
      return this.$route.query.cluster;
    },

    namespace() {
      return this.$route.query.namespace;
    },

    name() {
      return this.$route.params.name;
    },

    type() {
      return this.$route.params.type;
    }
  },

  methods: {
    getMilliSecond,

    emitTimeRange(start, end) {
      if (start > end) {
        this.$notify.error({
          title: "Error",
          message: "开始时间不能大于结束时间"
        });
      } else {
        this.popoverVisible = false;
        this.currentInterval = false;
        this.$emit("update:timeRange", [start, end]);
        this.$emit("timeRangeChange");
      }
    },

    timeRangeChange(item) {
      let endTime = this.moment().unix() * 1000;
      let startTime = endTime - getMilliSecond(item.value);

      this.emitTimeRange(startTime, endTime);
    },

    selectCustomTime() {
      this.emitTimeRange(this.startTime, this.endTime);
    },

    intervalSwitch() {
      this.currentInterval = !this.currentInterval;
      this.$emit("intervalChange");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/variables.scss";
@import "~@/styles/mixin.scss";
.chart-tools {
  margin-bottom: 20px;

  @include flex(flex-end);

  .time-range-text {
    font-size: 12px;
    color: $color-sub;
    margin-left: 2px;
  }

  & > div {
    margin-left: 8px;
  }
}

.time-range {
  @include flex(flex-start, flex-top, nowrap);

  & > div {
    width: 50%;
  }

  .time-range-custom {
    .title {
      margin-bottom: 6px;
    }

    margin-bottom: 10px;
  }

  .time-range-title {
    font-size: 14px;
    color: $color-sub;
    margin-bottom: 10px;
  }

  ul {
    font-size: 12px;
    overflow: hidden;

    li {
      width: 100px;
      line-height: 22px;
      color: $color-main;
      font-weight: 600;
      margin: 6px 0;
      cursor: pointer;
      float: left;

      &.active {
        color: $color-primary;
      }

      &:hover {
        color: $color-primary;
      }
    }
  }
}
</style>
